<template>
  <v-card flat>
    <v-card-title>Datos de contacto</v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="5">
          <v-text-field
            v-model="formData.email"
            label="Email"
            :rules="[$data.$globalRules.required, $data.$globalRules.email]"
            dense
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="formData.telephone"
            label="Teléfono"
            :rules="[$data.$globalRules.required]"
            dense
          />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            v-model="formData.country_id"
            :items="countries"
            :loading="loadingCountries"
            item-value="const"
            item-text="title"
            label="País"
            dense
          />
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="formData.address" label="Dirección" dense />
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field v-model="formData.province" label="Provincia" dense />
        </v-col>

        <v-col cols="12" sm="6" md="5">
          <v-text-field v-model="formData.city" label="Población" dense />
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field v-model="formData.postal_code" label="Código postal" dense />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    countries: [],
    loadingCountries: null,
  }),
  async mounted() {
    await this.getCountries();
  },
  methods: {
    async getCountries() {
      try {
        this.loadingCountries = true;
        const response = await this.$store.state.currentService.getCountries();
        this.countries = response.data;
      } catch (e) {
        this.$notifyError(undefined, "Error al escargar los paises disponibles | " + e);
      } finally {
        this.loadingCountries = false;
      }
    },
  },
};
</script>

<style scoped></style>
